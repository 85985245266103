<template>
    <div>
        <Header></Header>
        <!-- <div class="nbanner">
          <img src="@/assets/images/banner.png" alt="">
        </div> -->
        <div class="nnews">
          <div class="nnews2">
            <div class="ntitle">
                <a-breadcrumb separator=">">
                    <a-breadcrumb-item>
                        <router-link :to="{ name: 'Home' }">{{ $t('home') }}</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item href="/news">
                        <router-link :to="{ name: 'HomeNews' }">{{ $t('HybridExpo') }}</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        {{ $t('detail') }}
                    </a-breadcrumb-item>
                </a-breadcrumb>
            </div>
          </div>
          <div class="nnews3">
            <div class="ninfo">
                <div class="ititle">{{newslist.title}}</div>
                <div class="idata">{{newslist.publishedAt}}{{ newslist.fromPlat ? '  |  ' + newslist.fromPlat : '' }} </div>
                <div class="ilist news-content" v-html="newslist.content">
                   {{newslist.content}}
                </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>

import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: "News",
  components: {
    Footer,
    Header,
  },
  data () {
    return {
      newsId: this.$route.params.newsId,
      newslist: []
    }
  },
  mounted () {
    this.getList()
  },
   methods: {
     // 获取新闻详情
    getList () {
      this.$common.fetchList('/article/detail/'+this.newsId, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.newslist = result
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    }
   }
}
</script>

<style lang="less">
.nnews3{
  .ilist.news-content{
    img{
      max-width: 1200px;
    }
  }
}
</style>

<style lang="less" scoped>
  @import '~@/assets/css/newsdetail.less';
</style>